// .custom_thumbnail {
//   position: relative;
//   display: block;
//   overflow: hidden;
//   min-width: 4rem;
//   max-width: 100%;
//   //width: 6.2rem;
//   width: 100%;
//   border-radius: 3px;
//   background: #fff;

//   &::after {
//     content: "";
//     display: block;
//     padding-bottom: 100%;
//     border-radius: 3px;
//   }
//   img {
//     position: absolute;
//     z-index: 10;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     margin: auto;
//     max-width: 100%;
//     max-height: 100%;
//     height: unset;
//     width: unset;
//     contain-intrinsic-size: 160px 160px;
//     vertical-align: top;
//   }
// }

// .custom_thumbnail {
//   padding-top: 42%;
//   position: relative;
//   img {
//     contain-intrinsic-size: 160px 160px;
//     position: absolute;
//     margin: auto;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     max-width: 100%;
//     max-height: 100%;
//     vertical-align: top;
//     text-align: center;
//     height: unset;
//     width: unset;
//   }
// }
.custom_thumbnail {
  position: relative;
  display: block;
  overflow: hidden;
  //min-width: 4rem;
  max-width: 100%;
  width: 100%;
  border-radius: 3px;
  background: #fff;

  &::after {
    content: "";
    display: block;
    padding-bottom: var(--padding-bottom, 100%);
    border-radius: 3px;
  }

  img {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    height: unset;
    width: unset;
    contain-intrinsic-size: 160px 160px;
  }
}
